'use strict';
import React from 'react';
import $ from 'jquery';
import lozad from 'lozad';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// @PLUGIN: Location Handler
// Self invoking
// =============================
var observer = lozad();
// (function () {

    // var csrftoken = getCookie('csrftoken');
    // var toggler = $('#location_footer')

    // $('.location_form button[type=submit]', toggler).on('click', function(event){
    //     event.preventDefault();
    //     var val = $(this).val();
    //     var city_slug = $('#n_city').val();
    //
    //     if(val !== 'Cancel') {
    //         $.ajax({
    //           type: 'post',
    //           url: '/guardar-ubicacion/',
    //           data: {
    //             'csrfmiddlewaretoken': csrftoken,
    //             'show_cover': val,
    //             'city_slug': city_slug
    //           },
    //           success: function (response) {
    //             if (response.status === '1') {
    //                 window.location.replace(response.redirect_url);
    //             }
    //             else {
    //                 toggler.removeClass('active');
    //             }
    //           },
    //         });
    //     }
    //     else {
    //         toggler.removeClass('active');
    //     }
    // });

    // function getLocation() {
    //     var show_cover_cookie = getCookie('cover_to_show');
    //     if (! show_cover_cookie) {
    //         if (navigator.geolocation) {
    //             navigator.geolocation.getCurrentPosition(getPosition);
    //         }
    //     }
    // }

    // function getPosition(position) {
    //
    //     $.ajax({
    //       type: 'post',
    //       url: '/ciudad-cercana/',
    //       data: {
    //         'csrfmiddlewaretoken': csrftoken,
    //         'latitude': position.coords.latitude,
    //         'longitude': position.coords.longitude
    //       },
    //       success: function (response) {
    //         if (response.status === '1') {
    //             // toggler.addClass('active');
    //             $('.n_location', toggler).text(response.city.name);
    //             $('#n_city').val(response.city.slug)
    //         }
    //         else if (response.status === '2') {
    //             // toggler.addClass('active');
    //             $('.location-text', toggler).text(response.message);
    //             $('#n_city').val('default');
    //         }
    //         else {
    //             return;
    //         }
    //       },
    //     });
    // }

    // getLocation();

// })();


// Carousel | streamer | lo mas visto
// ================================
(function () {

    // define variable
    var container = '#most-viewed';

    $(container).slick({
        lazyLoad: 'ondemand',
        mobileFirst: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        infinite: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-2x fa-chevron-left" aria-hidden="true"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-2x fa-chevron-right" aria-hidden="true"></i></button>',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
})();

// Carousel | news ranking
// ================================
(function () {

    // define variable
    var container      = '.filtered-tab-carousel',
        initial_filter = 'top_day',
        attribute      = 'data-sort-type',
        $newsRanking;

    $newsRanking = $(container + " .slick").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        infinite: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    function unFilter() {
        $newsRanking.slick('slickUnfilter');
    }

    function filter(sort_type) {
        $newsRanking.slick("slickFilter", "li[" + attribute + "='" + sort_type + "']");
    }

    filter(initial_filter);

    $(document)
      .on('hide.bs.tab', container, unFilter)
      .on('show.bs.tab', container, function (e) {
        filter($(e.target).attr(attribute));
      });
})();

// Carousel | news ranking
// ================================
(function () {

    // define variable

    var container      = '#index-gallery',
        attribute      = 'sort',
        $mainCarousel = $('.carousel-pictures', container),
        $thumsCarousel = $('.carousel-thumbnails', container);

    var initial_filter = $('.slick-carousel figure', container).first().data(attribute);

    function unFilter() {
        $mainCarousel.slick('slickUnfilter');
        $thumsCarousel.slick('slickUnfilter');
    }

    function filter(sort_type) {
        $mainCarousel.slick("slickFilter", "figure[data-" + attribute + "='" + sort_type + "']");
        $thumsCarousel.slick("slickFilter", "div[data-" + attribute + "='" + sort_type + "']");

        reIndexSlide($mainCarousel);
        reIndexSlide($thumsCarousel);
    }

    function reIndexSlide($slick){
        var $slides = $slick.find('.slick-active');
        $slides.each(function(index, slide) {
            $(slide).attr('data-slick-index', index);
        });
        $slick.slick('slickGoTo',0,true);
    }

    filter(initial_filter);

    $('.nav-item .nav-link', container).on("click", function(event) {
        $('.nav-item .nav-link', container).removeClass('active');
        $(this).addClass('active');
        unFilter();
        filter( $(this).data(attribute) );
    });

})();

// telejunin | Video Carousel
// ================================
(function () {

    var toggler = $('#telejunin-video-slider');

    if ( toggler.length > 0 ){
        toggler.slick({
            arrows: true,
            // lazyLoad: 'progressive',
            variableWidth: true,
            slidesToShow: 1,
            infinite: true,
            prevArrow: '',
            nextArrow: '<div><button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button></div>',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        variableWidth: false
                    }
                },
            ]
        });
        //  Move carousel if click in next element
        $('.slick-slide', toggler).on('click', function(event) {
            if (!$(this).hasClass('slick-active')) {
                event.preventDefault();
                toggler.slick('slickNext');
            }
        });
    }
})();

// @PLUGIN: Cover Widget
// Self invoking
// =============================
(function () {
  $('#destacados_cover_widget .dropdown-item').on('click', function () {
    $('#destacados_cover_widget .lazy-embed').remove();
    var embed_code = $(this).data('embed-code');
    var src_placeholder = $(this).data('placeholder');
    $('#destacados_cover_widget .panel-body').append('<div class="lazy-embed">'+ embed_code +'</div>');
  })
})();

// React Init Components
// ================================
(function () {

    function initReactComponents(exclude) {
        var bigExclude = exclude;

        var sections = [
            { id: 1, name: 'Policiales' }, { id: 10, name: 'Provinciales' },
            { id: 8, name: 'Medio del Campo' }, { id: 51, name: 'Diseño & Construcción' }
        ];

        var masDeportivoSections = [
            { id: 20, name: 'Fútbol' }, { id: 21, name: 'Básquet' },
            { id: 22, name: 'Automovilismo' }, { id: 24, name: 'Boxeo' },
            { id: 26, name: 'Polideportivo' }
        ];

        var vidaSections = [
            { id: 39, name: 'Sociedad' }, { id: 40, name: 'Espectáculos' },
            { id: 41, name: 'Tecnología' }, { id: 42, name: 'Insólitas' }
        ];

        var sectionElement = document.getElementById('sectionsPanel'),
            masDeportivoElement = document.getElementById('masDeportivoPanel'),
            vidaElement = document.getElementById('vidaSectionsPanel'),
            moreNewsElement = document.getElementById('more_news_lazy');

        if(sectionElement) {
            var sectionPanel = React.createElement(SectionsBlock, {exclude: bigExclude, sections: sections, initialSection: 1});
            ReactDOM.render(sectionPanel, sectionsPanel);
        }
        if(masDeportivoElement) {
            var masDeportivoPanel = React.createElement(MasDeportivoBlock, {exclude: bigExclude, sections: masDeportivoSections, initialSection: 20});
            ReactDOM.render(masDeportivoPanel, masDeportivoElement);
        }
        if(vidaElement) {
            var vidaPanel = React.createElement(VidaBlock, {exclude: bigExclude, sections: vidaSections, initialSection: 39});
            ReactDOM.render(vidaPanel, vidaElement);
        }
        if(moreNewsElement) {
            var moreNewsPanel = React.createElement(OtherNewsBlock, {exclude: bigExclude, sections: SECTIONS, city: CITY_ID });
            ReactDOM.render(moreNewsPanel, moreNewsElement);
        }
    }

    initReactComponents(BigExclude);

})();

// Modal Ads
// ================================
(function () {

    function displayAd (elem) {

        var duration = $( elem ).data( 'duration' ) * 1000;

        $( elem ).modal( 'show' );
        setTimeout(
            function () {
                $( elem ).modal( 'hide' )
            },
            duration
        );
        // set the coookie
        setCookie('active_model', true);
    }

    $( document ).ready( function () {
        // Show Ad
        var democracia_modal = $('#democracia_modal_ad');

        if ( democracia_modal.length > 0 ) {
            if (getCookie('active_model') !== 'true'){
                displayAd('#democracia_modal_ad');
            }
        }

    } );
})();

(function(){
    // Fix for lazyload on tabs for ranking news widget on index page
    $('.section-ranking-noticias').on('DOMSubtreeModified', ".tab-pane", function(){
        $(this).find('.lazyload').each(function() {
            var imageSrc = $(this).attr("data-src");
            $(this).attr("src", imageSrc).removeAttr("data-src");
        })
    });
})();

(function (){
    observer.observe();
})();

